import React, { Children, isValidElement } from "react";
import { Provider } from "react-redux";
import { Routes, Route } from "react-router-dom";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { store, history } from "./redux/store";
import { ProtectedRoute, protectedRoutes } from "./navigation/ProtectedRoute";
import Login from "./features/account/Login";

export { useControlXState } from "./hooks/useControlXState";
export * from "./components";
export type { RootState } from "./redux/store";
export const Page: React.FC<PageProps> = () => null;
export interface PageProps {
  route: string;
  component: React.ReactNode;
}

export const ControlX: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const publicRoutes = Children.toArray(children)
    .filter(isValidElement)
    .filter((child) => child.type === Page)
    .map((child, index) => {
      const { route, component } = child.props as PageProps;
      return <Route key={index} path={route} element={component} />;
    });

  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes>
          {publicRoutes}
          {protectedRoutes.map((item, index) => (
            <Route
              key={100 + index}
              path={item.route}
              element={<ProtectedRoute>{item.component}</ProtectedRoute>}
            />
          ))}
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </Provider>
  );
};
